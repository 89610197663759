import React, {useState, useEffect, useContext} from 'react'
import { graphql } from "gatsby"
import queryString from 'query-string'

import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import ReactHtmlParser from 'react-html-parser'

//import GMap from '../components/Map/GMap'
//import Video from '../components/Video/HTML'

import withLocation from "../components/HOC/withLocation"
import APIContext from '../context/APIContext'

import EventRegular from '../components/Event/Regular'
import EventProprietary from '../components/Event/Proprietary'
import Footer from '../components/Footer'

function EventPage(props) {
  const apiContext = useContext(APIContext)
  const [eventObject, setEventObject] = useState()

	const search = props.location.search ? queryString.parse(props.location.search) : {}
  //console.log(props.location.search,search)
  
	// API
	const apiRequest = {
		id: search.id,
		path: 'event',
		refresh: 60000
	}
	useEffect(() => {
		// Set the API needed for current page
		apiContext.setAPI(apiRequest)
  },[])

	useEffect(() => {
		if(apiContext.data) {
      let obj = apiContext.data.find(o => o.id === parseInt(search.id))
      setEventObject(obj)
		}
  },[apiContext.data])

  

  let EventDrop = null
  if(eventObject) {
    if(eventObject.acf.type === 'proprietary') {
      EventDrop = <EventProprietary {...eventObject} />
    } else {
      EventDrop = <EventRegular {...eventObject} />
    }
  }
  
  const footerImage = props.data.wordpressAcfOptions.options.footer_image.localFile?.childImageSharp.fluid
  const footerCopy = props.data.wordpressAcfOptions.options.footer_copy
  
  return (
		<>
      <main className={`c0 is-inview main-${props.pageContext.slug === "/" ? "frontpage" : props.pageContext.slug}`}>

        {props.data.wordpressPage?.yoast_head ?
          <Helmet>{ ReactHtmlParser(props.data.wordpressPage?.yoast_head) }</Helmet>
        :
          <SEO title={props.data.wordpressPage?.name} description={props.data.wordpressPage?.description} />
        }

        {EventDrop}

      </main>

      <Footer image={footerImage} copy={footerCopy} />
		</>	
  )
}

export default withLocation(EventPage)


export const query = graphql`
  query {
    wordpressAcfOptions {
      options {
        footer_image {
          localFile {
            childImageSharp {
              fluid (
                maxWidth: 1280,
                quality: 90,
                srcSetBreakpoints: [720,1280,1920,2560]
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        footer_copy
      }
    }
  }
`
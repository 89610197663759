import React, {useState, useEffect} from 'react'

import GMap from '../../Map/GMap'
import Video from '../../Video/HTML'
import GalleryAPI from '../../GalleryAPI'
import Tabs from '../../Tabs'
import QA from '../../Content/ACF/FAQ/QA'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import './style.scss'

function EventRegular({...props}) {
  const [countDown, setCountDown] = useState()
	const eventStartDate=new Date(props.acf?.event_start_daytime.replace(/-/g, "/"))
	const eventEndDate=new Date(props.acf?.event_ends_daytime.replace(/-/g, "/"))
	const eventTitle=props.title?.rendered
	const eventGeneralLocation=props.acf?.general_location
	const eventIMG=props.acf?.img
	const eventDescription=props.acf?.description
	const eventDescriptionSide=props.acf?.description_side
	const eventTickets=props.acf?.tickets
	const eventMap=props.acf?.map
	const eventVideo=props.acf?.video
	const eventVideoEmbedd=props.acf?.video_embedd
  const eventRefunds=props.acf?.refund_policy
	const eventGallery=props.acf?.gallery
	const eventFaq=props.acf?.faq
  const eventTabs=props.acf?.tabs
	const eventFeatures=props.acf?.features
	const eventReoccuring=props.acf?.reoccuring

	const featuresMap = eventFeatures?.map((node,i) => {
		return (
			<div className="feature" dangerouslySetInnerHTML={{__html: node.feature}}></div>
		)
	})

  
  useEffect(() => {
    // Update the count down every 1 second
    let interval = setInterval(function() {

      setCountDown(() => {
        // Event DateTime
        let countDownDate = eventStartDate.getTime()
        let countDownExpire = eventEndDate.getTime()

        // Get todays date and time
        let now = new Date().getTime()
      
        // Find the distance between now and the count down date
        let distance = countDownDate - now
        let expire = countDownExpire - now
      
        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24))
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        let seconds = Math.floor((distance % (1000 * 60)) / 1000)

        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
          expire: expire
        }
      })
    }, 1000)
    return () => clearInterval(interval)
  },[eventStartDate])

  

  // Main Tab
  let tabs = [
    {
      tab: <h3>Case Study</h3>,
      tabContent: <>
        {eventDescription ?
          <div className="event-details-main" dangerouslySetInnerHTML={{__html: eventDescription}}></div>
          :
          <div className="event-details-main">
            <h3 className="placeholder wave longer">
              <span className="line"></span>
              <span className="line"></span>
            </h3>
            <p className="placeholder wave longer">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </p>
            <p className="placeholder wave longer">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </p>
          </div>
        }
      </>
    },
  ]
  // FAQ Tab
  const faqList = eventFaq?.map((node, i) => {
    return (
      <QA i={i} { ...node } />
    )
  })
  if(faqList) {
    const tabFaq = [
      {
        tab: <h2>FAQ</h2>,
        tabContent: <>{faqList}</>
      }
    ]
    tabs.push(...tabFaq)
  }
  // Extra Content Tabs
  const extraTabs = eventTabs?.map(prepTabs)
  function prepTabs(item, i) {
  	return {
			tab: <div dangerouslySetInnerHTML={{__html: item.tab}} />,
			tabContent: <div dangerouslySetInnerHTML={{__html: item.tabcontent}} />
		}
  }
  if (extraTabs){
    tabs.push(...extraTabs)
  }
    
  return (
		<div className="event-type proprietary">

      <section className="event-hero c0 border is-inview">
        <div className="animated-border-top"></div>
        <div className="animated-border-right"></div>
        <div className="animated-border-left"></div>
        <div className="event-hero-img c5 relative">
          {eventIMG ?
            <>
              <img
                className="animated img-cover"
                src={eventIMG.url} 
                alt=""  
                srcSet={`${eventIMG.sizes.large} 1024w,
                    ${eventIMG.sizes.medium_large} 768w,
                    ${eventIMG.sizes.medium} 300w,
                    ${eventIMG.sizes.thumbnail} 150w`}
                sizes="auto"
              />
              <div className="bg-overlay">
                <div className="bg-overlay-x"></div>
              </div>
              {eventTitle ? 
                <div className="event-title">
                  <h1 dangerouslySetInnerHTML={{__html: eventTitle}}></h1>
                  {eventGeneralLocation ? <h3><FontAwesomeIcon icon={faMapMarkerAlt} />{eventGeneralLocation}</h3> : null}
                  
                  {eventStartDate && eventReoccuring === 'false' ?
                    <h2 className="date-title">
                      {eventStartDate?.toLocaleDateString('en-US', {weekday: 'long'})}, {eventStartDate?.toLocaleDateString('en-US', {month: 'long'})} {eventStartDate?.toLocaleDateString('en-US', {day: 'numeric'})}
                      {eventEndDate ? 
                        <>
                          - {eventEndDate?.toLocaleDateString('en-US', {weekday: 'long'})}, {eventEndDate?.toLocaleDateString('en-US', {month: 'long'})} {eventEndDate?.toLocaleDateString('en-US', {day: 'numeric'})}
                        </>
                      : null }
                    </h2>
                  : null }
                  
                  {eventTickets ? <a href={eventTickets} className="btn flat tickets"><span>GET Tickets</span></a> : null }
                </div>
              : null}
              {countDown && eventReoccuring === 'false' ?
                <h2 className="date">
                  {countDown.days > 0 || countDown.hours > 0 || countDown.minutes > 0 || countDown.seconds > 0 ?
                    <h6 className="monofont countdown">
                      <span><small>Event starts in: </small></span>
                      {countDown.days > 0 ? <span className="monofont timeblock">{countDown.days}{countDown.days === 1 ? <small>day</small> : <small>days</small>}</span> : null}
                      {countDown.hours >= 0 ? <span className="monofont timeblock">{countDown.hours}{countDown.hours === 1 ? <small>hr</small> : <small>hrs</small>}</span> : null}
                      {countDown.minutes >= 0 ? <span className="monofont timeblock">{countDown.minutes}{countDown.minutes === 1 ? <small>min</small> : <small>mins</small>}</span> : null}
                      {countDown.seconds >= 0 ? <span className="monofont timeblock">{countDown.seconds}{countDown.seconds === 1 ? <small>sec</small> : <small>secs</small>}</span> : null}
                    </h6>
                  : countDown.expire > 0 ?
                    <h6 className="monofont countdown"><span><small>Event is now live!</small></span></h6>
                  : <h6 className="monofont countdown"><span><small>Event has ended</small></span></h6> }
                </h2>
              : null }
              {eventReoccuring && eventReoccuring !== 'false' ?
                <h2 className="date">
                  <h6 className="monofont countdown"><span><small>{eventReoccuring}</small></span></h6>
                </h2>
              : null }
            </>
          :
            <div className="image-placeholder"></div>
          }
        </div>
        <div className="event-hero-gallery-n-tickets">

          {eventGallery ?
            <section className={`border c5 border-left-off border-right-off grid-12 content-box`}>
              <div className="span-12 gallery-center">
                <GalleryAPI gallery={eventGallery} />
              </div>
            </section>
          : null }
        
        </div>
      </section>

      {tabs ?
        <section className="event-main border grid-12 is-inview">
          <Tabs tabs={tabs} tabWrapClass='event-tabs' tabContentWrapClass='event-tabs-content c0 padd-2'/>

          <div className="event-details event-details-side c5 fs-85 padd-2">
            {eventStartDate && eventReoccuring === 'false' ?
              <>
                <div className="event-start-daytime"><h6>Date And Time</h6><h5>{eventStartDate.toLocaleDateString('en-US', {weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'})} | {eventStartDate?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</h5></div>
                {eventEndDate ?
                  <div className="event-start-daytime"><h6>Ends on</h6><h5>{eventEndDate.toLocaleDateString('en-US', {weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'})} | {eventEndDate?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</h5></div>
                : null }
              </>
            : null }
            {eventRefunds ?
              <div className="event-refunds"><h6>Refunds</h6><h5>{eventRefunds}</h5></div>
            :
              null
            }
            {eventDescriptionSide ?
              <div className="event-description-side" dangerouslySetInnerHTML={{__html: eventDescriptionSide}}></div>
            :
              null
            }
            {eventMap ?
              <>
                <div className="event-location"><h6>Location</h6><h5>{eventMap.address}</h5></div>
                {/* <div className="event-map">
                  <div id="map" className="map" style={{width: '100%', height: '100%'}}>
                    <GMap { ...eventMap } />
                  </div>
                </div> */}
              </>
              :
              <h6 className="placeholder wave longer">
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </h6>
            }

            {eventTickets ? <div className="event-hero-details-bottom c5"><a href={eventTickets} className="btn flat tickets"><span>GET Tickets</span></a></div> : null }

          </div>
        </section>
      : null }  

			{featuresMap ?
				<section className="event-details c0 border grid-12 is-inview">
					<div className="animated-border-top"></div>
					<div className="animated-border-right"></div>
					<div className="animated-border-left"></div>
	
							<div className="span-12 padd-2 grid-12">
								<div className="span-12 features">
									{featuresMap}
								</div>
							</div>
				</section>
			: null }

        {eventVideo || eventVideoEmbedd ?
          <section className="event-video c5 border grid-12 is-inview">
            <div className="animated-border-top"></div>
            <div className="animated-border-right"></div>
            <div className="animated-border-left"></div>
            <div className="video-wrap span-12">
              {eventVideoEmbedd ?
                <div className="video-embedd" dangerouslySetInnerHTML={{__html: eventVideoEmbedd}}></div>
              : <Video file={eventVideo.url} />}
            </div>
          </section>
        : null }
		</div>	
  )
}

export default EventRegular
import React, {useContext, useState, useEffect} from 'react'

//import PropTypes from 'prop-types'
import './style.scss'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import FSLightBoxContext from '../../context/FSLightBoxContext'

function Gallery(props) {
	const lightBoxContext = useContext(FSLightBoxContext)
	const [gallery, setGallery] = useState([])
	const [slidesPack, setSlidesPack] = useState()
	
	const slides = props.gallery
	const settings = {
		dots: false,
		arrows: true,
		infinite: true,
		speed: 800,
		pauseOnFocus: true,
		autoplay: true,
		autoplaySpeed: 8000,
		slidesToShow: 3,
		slidesToScroll: 3,
		accessibility: false,
		fade: false,
		focusOnSelect: false,
		adaptiveHeight: false,
		centerMode: false,
		variableWidth: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 720,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
		]
	}

	useEffect(() => {

		setSlidesPack(() => {
			// Prep array for the slides in lightbox gallery
			let lightboxSlidesArray = []

			// Map Slides to gallery stripe
			const slidesMap = slides.map((slide, i) => {
				// Add the image url to lightbox gallery array
				lightboxSlidesArray.push(slide.url)

				return (
					<div key={`slide-${i}`} className="slide x1">
						{ slide ?
							<div onClick={() => { lightBoxContext.setToggler((toggle) => !toggle); lightBoxContext.setSlide(i + 1) }} className="gatsby-image-wrapper" style={{position: 'relative', display: 'inline-block', overflow: 'hidden', width: slide.width, height: slide.height}}>
								<img
									className="img-cover"
									src={slide.url} 
									alt=""
									srcSet={`${slide.sizes.large} 1024w,
											${slide.sizes.medium_large} 768w,
											${slide.sizes.medium} 300w,
											${slide.sizes.thumbnail} 150w`}
									sizes="auto"
								/>
							</div>
						: null }
					</div>
				)
			})
			// Update lightbox gallery array
			lightBoxContext.setSources(() => lightboxSlidesArray)
			return slidesMap
		})
	}, [slides])

	return (
		<Slider {...settings}>
			{slidesPack ? slidesPack : null}
		</Slider>
  )
}

export default Gallery